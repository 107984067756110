<template>
  <div class="wrapperlogin">
    <div class="loginav">
      <div class="video">
        <video src="./images/video.mp4" muted autoplay loop />
      </div>
      <div class=""></div>
      <div class="loginbox boxall">
        <div class="logo">
          <h1>标讯管理后台</h1>
          <!-- <h2>zhi neng ke ji ping tai</h2> -->
          <!-- <img src="images/logo.png"> -->
        </div>
        <div class="logintit">用户登录</div>
        <ul class="logininput">
          <li>
            <i><img src="./images/user.png" /></i>
            <input class="forminput" v-model.trim="formData.account" type="text" placeholder="用户名" />
          </li>
          <li>
            <i><img src="./images/password.png" /></i>
            <input class="forminput" type="password" v-model.trim="formData.password" placeholder="密码" />
          </li>
          <li>
            <i style="font-size: 26px; color: #0b93e0; font-weight: 700" class="el-icon-key">
              <!-- <img src="./images/password.png" /> -->
            </i>
            <input class="forminput" type="text" v-model.trim="formData.yzm" placeholder="验证码" />
            <img class="yzm" :src="imgCode" alt="" @click="getImgCode" />
          </li>

          <!-- <div class="flex1" style="padding: 15px 0">
            <label> <input type="checkbox" checked class="aui-checkbox" />&nbsp;记住密码</label>
            <a href="index.html#" class="text-primary"> 忘记密码？</a>
          </div> -->
          <li>
            <a href="javascript:;" class="btn btnblock btn-lg btn-block btn-primary" @click.prevent="onSubmit">登录</a>
          </li>
        </ul>
        <div class="boxfoot"></div>
      </div>
    </div>

    <canvas id="canvas" style="position: fixed; width: 100%; height: 100%; left: 0; top: 0; opacity: 0.3; pointer-events: none"></canvas>
  </div>
</template>

<script>
import { getImgCodeAPI } from './api'
export default {
  name: 'Login',

  data() {
    return {
      imgCode: '',
      formData: {
        account: 'admin',
        password: '123456',
        yzm: '',
        uniqid: ''
      },
      rules: {}
    }
  },

  mounted() {
    this.getImgCode()
  },

  methods: {
    onSubmit() {
      this.$store
        .dispatch('Login', this.formData)
        .then(() => {
          this.$router.push({ path: '/home' })
          sessionStorage.removeItem('keyPath')
          this.$store.dispatch('tagsView/delAllViews')
        })
        .catch(() => {
          this.getImgCode()
        })
    },
    async getImgCode() {
      const res = await getImgCodeAPI()
      this.formData.uniqid = res.uniqid
      this.imgCode = res.image
    }
  }
}
</script>

<style lang="css" scoped>
@import './css/style.css';
.wrapperlogin {
  height: 100vh;
}
.logininput .yzm {
  position: absolute;
  top: 16px;
  right: 2px;
  background: #fff;
  height: 50px;
  width: auto;
}
.loginbox {
  margin-right: 5%;
}
</style>
